/** @jsx jsx */

import { graphql } from 'gatsby';
import { Fragment, useState } from 'react';
import type { MagicalNodeRenderers } from '@magical-types/pretty';
import {
  PropTypes,
  RenderersContext,
  Type,
  Types,
  defaultRenderers,
} from '@magical-types/pretty';
import type { MagicalNode, MagicalNodeIndex } from '@magical-types/types';
import { jsx } from '@balance-web/core';
import {
  TabItem,
  TabList,
  useTabList,
  useTabListState,
} from '@balance-web/tabs';
import { useTheme } from '@balance-web/theme';

import type { ApiPageQuery } from '../../graphql-types';
import { Package } from '../components/Package';
import { metadata, useMagicalNodes } from '../components/types';

let getMaybeNameForPropsToHideByDefault = (node: MagicalNode) => {
  if (
    node.type === 'Object' &&
    node.name === 'Pick' &&
    node.aliasTypeArguments[0] &&
    node.aliasTypeArguments[0].type === 'Object' &&
    node.aliasTypeArguments[0].name?.endsWith('HTMLAttributes')
  ) {
    return node.aliasTypeArguments[0].name;
  }
  if (
    node.type === 'Object' &&
    node.name &&
    (node.name.endsWith('HTMLAttributes') ||
      node.name === 'MarginProps' ||
      node.name === 'PaddingProps' ||
      node.name === 'ChildFlexProps' ||
      node.name === 'BaseBoxProps')
  ) {
    return node.name;
  }
};

function OurPropTypes({ node }: { node: MagicalNode }) {
  const { palette } = useTheme();

  if (node.type === 'Intersection') {
    return (
      <Fragment>
        {node.types.map((node) => {
          let name = getMaybeNameForPropsToHideByDefault(node);
          if (name) {
            return (
              <details
                key={node.type}
                css={{
                  border: `1px ${palette.global.border} solid`,
                  padding: 4,
                }}
              >
                <summary css={{ cursor: 'pointer' }}>
                  <Type>{name}</Type>
                </summary>
                <PropTypes node={node} />
              </details>
            );
          }
          return <PropTypes node={node} key={node.type} />;
        })}
      </Fragment>
    );
  }
  return <PropTypes node={node} />;
}

const ourRenderers: MagicalNodeRenderers = {
  ...defaultRenderers,
  Union: function UnionRenderer({ node, path }) {
    if (node.name === 'ReactNode' || node.name === 'ReactElement') {
      return <Type>{node.name}</Type>;
    }
    return <defaultRenderers.Union node={node} path={path} />;
  },
};

function ShowTypes({
  exportsMeta,
  getNode,
}: {
  getNode: (index: MagicalNodeIndex) => MagicalNode;
  exportsMeta: Record<
    string,
    {
      type: 'component' | 'other';
      index: MagicalNodeIndex;
    }
  >;
}) {
  let [listRef, setListRef] = useState<HTMLDivElement | null>(null);

  const tabs = Object.keys(exportsMeta).map((key) => {
    let node = getNode(exportsMeta[key].index);
    return {
      label: key,
      panel:
        exportsMeta[key].type === 'component' ? (
          <OurPropTypes node={node} />
        ) : (
          <Types node={node} />
        ),
    };
  });
  const selectedIndex = useTabListState(listRef);
  const { getPanelAttributes, getTabAttributes, listProps } = useTabList(
    selectedIndex
  );
  return (
    <RenderersContext.Provider value={ourRenderers}>
      <TabList {...listProps} ref={setListRef}>
        {tabs.map(({ label }, index) => {
          return (
            <TabItem
              key={label}
              isSelected={index === selectedIndex}
              {...getTabAttributes(index)}
            >
              {label}
            </TabItem>
          );
        })}
      </TabList>
      {tabs.map(({ label, panel }, index) => {
        return (
          <div key={label} {...getPanelAttributes(index)}>
            {index === selectedIndex ? panel : null}
          </div>
        );
      })}
    </RenderersContext.Provider>
  );
}

const APIPage = (props: { data: ApiPageQuery }) => {
  let pkg = props.data.package!;

  let getNode = useMagicalNodes();

  return (
    <Package pkg={pkg}>
      <div css={{ display: 'flex' }}>
        <div css={{ flex: 1, minWidth: 0 }}>
          {getNode ? (
            <ShowTypes
              getNode={getNode}
              exportsMeta={metadata[pkg.nameWithScope]}
            />
          ) : (
            'Loading...'
          )}
        </div>
      </div>
    </Package>
  );
};

export const pageQuery = graphql`
  query APIPage($id: String!) {
    package(id: { eq: $id }) {
      ...PackageMetadata
    }
  }
`;

export default APIPage;
